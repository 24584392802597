import { bus, api } from 'shared/core';

class Channel {
  url = '/dashboard/profile/get-channel/';
  endpoint = api.endpoint;

  handler = (ev) => {
    try {
      const message = JSON.parse(ev.data);

      if (message && message.data && message.data.chat_message) {
        return bus.emit('chatMessage', message.data.chat_message);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default new Channel();
