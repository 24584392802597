/* eslint-disable no-shadow, no-param-reassign, indent */

import * as fns from 'date-fns';
import { bus } from 'shared/core';
import { supportAPI } from '@/apps/support/api';
import { uniqBy, cloneDeep } from 'lodash';

const initialState = () => ({
  input: {},
  attachedFiles: {},
  loading: false,
  chats: {
    items: [],
    meta: {},
  },
  messages: {},
  activeChatId: null,
  editMessages: {},
  type: null,
});

const state = initialState();

const getters = {
  loading: (state) => state.loading,
  input: (state) => (id) => state.input[id],
  attachedFiles: (state) => (id) => state.attachedFiles[id] || [],
  editMessages: (state) => (chatId) => state.editMessages[chatId] || {},
  chats: (state) => state.chats.items,
  hasChats: (_state, getters) => !!getters.chats.length,
  messages: (state) => state.messages,
  activeChatId: (state) => state.activeChatId,
  type: (state) => state.type,
};

const actions = {
  flush: ({ commit }) => {
    commit('_flush');
  },

  setType: ({ commit }, value) => {
    commit('_setType', value);
  },

  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setEditMessages: ({ commit }, { id, message }) => {
    commit('_setEditMessages', { id, message });
  },

  setAttachedFiles: ({ commit }, { id, value }) => {
    commit('_setAttachedFiles', { id, value });
  },

  removeAttachedFile: async ({ getters, dispatch }, { id, value }) => {
    const filteredFiles = getters.attachedFiles(id).filter((f) => f.src.name !== value.src.name);

    await dispatch('setAttachedFiles', { id, value: filteredFiles });
  },

  setInputAction: ({ commit }, { id, message }) => {
    commit('setInput', { id, message });
  },

  setMessagesAction: ({ commit }, { chat, messages }) => {
    commit('setMessages', { chat, messages });
  },

  fetchChatsAction: async ({ state, getters, commit, dispatch }, opts = {}) => {
    const { force = false } = opts;
    if (!force && getters.loading) return;

    const { meta } = state.chats;

    let result;

    try {
      dispatch('setLoadingAction', true);

      if (!meta.page) result = await supportAPI.getChatList({ type: getters.type });
      else if (meta.page >= meta.total_pages) return;
      else
        result = await supportAPI.getChatList({
          ...meta,
          page: meta.page + 1,
          type: getters.type,
        });

      commit('setChats', result);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  fetchChatAction: async ({ getters, commit, dispatch }, opts = {}) => {
    const { id, force = false } = opts;

    if (!force && getters.loading) return;

    try {
      dispatch('setLoadingAction', true);
      const chat = await supportAPI.getChatById({ id });
      if (chat) commit('addChat', chat);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  sendMessageAction: async ({ dispatch, getters }, opts = {}) => {
    if (getters.loading) return;

    const id = getters.activeChatId;
    const message = getters.input(id);
    const editMessage = getters.editMessages(id);
    const attachments = getters.attachedFiles(id);

    const isEdit = !!editMessage.id;

    try {
      dispatch('setLoadingAction', true);

      if (isEdit) {
        if (!editMessage.text) return;

        dispatch('setEditMessages', { id });

        await supportAPI.editMessage({
          chat_id: id,
          chat_message_id: editMessage.id,
          text: editMessage.text,
        });
      } else {
        if (!message) {
          if (!attachments.length) return;
        }

        dispatch('setInputAction', { id, message: '' });

        if (message) {
          await supportAPI.sendMessage({
            chat_id: id,
            text: message,
          });
        }

        if (attachments.length) {
          for (const file of attachments) {
            const { preview } = file;

            try {
              await supportAPI.uploadFile({ file: preview.base64, chat_id: id });
              await dispatch('removeAttachedFile', { id, value: file });
            } catch (e) {
              console.error(e);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('setLoadingAction', false);
    }
  },

  /**
   * chat: {items: []}
   * messages: {id: {items: []}}
   */
  addMessageAction: async ({ state, commit, dispatch }, value) => {
    const getChat = async () => {
      try {
        const chat = await supportAPI.getChatById({ id: value.chat_id });

        commit('addChat', chat);

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    let chat = state.chats.items.find((c) => c.id == value.chat_id);

    // TODO: consider to get chat and only then add message;
    if (!chat) {
      const isAdded = await getChat();
      if (!isAdded) return;
    }

    chat = state.chats.items.find((c) => c.id == value.chat_id);

    if (!state.messages[chat.id]) {
      console.log('try to fetch messages for chat', chat.id);

      try {
        const messages = await supportAPI.getChatMessages({ chat_id: chat.id });

        await dispatch('setMessagesAction', { chat: chat.id, messages });
      } catch (error) {
        console.error(error);
        return;
      }
    }

    const newMessageItems = [value, ...state.messages[chat.id].items];

    const payload = {
      chat: chat.id,
      messages: { meta: state.messages[chat.id].meta, items: newMessageItems },
    };

    commit('setMessages', payload);
    bus.emit('chatMessageAdd', value);
  },

  markMessageAsReadAction: async ({ commit }, { chat_id, id }) => {
    try {
      await supportAPI.markMessageAsRead({ chat_id, id });

      const chat = state.chats.items.find((c) => c.id === chat_id);

      const newMessageItems = state.messages[chat.id].items.map((item) => {
        if (item.id === id) item.is_read = true;
        return item;
      });

      const payload = {
        chat: chat.id,
        messages: { meta: state.messages[chat.id].meta, items: newMessageItems },
      };

      commit('setMessages', payload);
    } catch (error) {
      console.error(error);
    }
  },

  setActiveChatIdAction: ({ commit }, value) => {
    commit('setActiveChatId', value);
  },
};

const mutations = {
  _flush: (state) => {
    Object.assign(state, initialState());
  },

  _setType: (state, value) => {
    state.type = value;
  },

  _setEditMessages: (state, { id, message }) => {
    state.editMessages = { ...state.editMessages, [id]: message ? cloneDeep(message) : {} };
  },

  _setAttachedFiles: (state, { id, value }) => {
    state.attachedFiles = { ...state.attachedFiles, [id]: value };
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setInput: (state, { id, message }) => {
    state.input = { ...state.input, [id]: message };
  },

  setChats: (state, { items, meta }) => {
    let allChats = [...state.chats.items, ...items];

    const uniqueIds = new Set(allChats.map((c) => c.id));
    const uniqueChats = [...uniqueIds].map((id) => allChats.find((chat) => chat.id === id));

    state.chats = { items: uniqueChats, meta };
  },

  addChat: (state, value) => {
    const allChats = [...state.chats.items, value];
    const uniqueIds = new Set(allChats.map((c) => c.id));
    const uniqueChats = [...uniqueIds].map((id) => allChats.find((chat) => chat.id === id));

    state.chats = { items: uniqueChats, meta: state.chats.meta };
  },

  setMessages: (state, { chat, messages }) => {
    const { items, meta } = messages;

    const targetItems = state.messages[chat]
      ? uniqBy([...items, ...state.messages[chat].items], 'id')
      : items;

    const newItems = targetItems.sort((m1, m2) =>
      fns.compareAsc(new Date(m1.created_at), new Date(m2.created_at)),
    );

    state.messages = { ...state.messages, [chat]: { items: newItems, meta } };
  },

  setActiveChatId: (state, value) => {
    state.activeChatId = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
