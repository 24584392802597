import { bus } from 'shared/core';

class SupportEvent {
  _store;

  init(store) {
    this._store = store;
    bus.on('chatMessage', this.onChatMessage);
  }

  onChatMessage = (message) => {
    this._store.dispatch('support$support/addMessageAction', message);
  };
}

export default new SupportEvent();
