/* eslint-disable no-shadow, no-param-reassign */
import { orderAPI } from '@/core/api/order';

const state = {
  orders: {},
  loading: false,
};

const getters = {
  orders: (state) => state.orders,
  loading: (state) => state.loading,
  ordersByCustomerId: (state) => (id) => state.orders[id],
  hasOrders: (state) => (id) => !!state.orders[id],
};

const actions = {
  fetchOrdersAction: async ({ commit }, opts = {}) => {
    const { customer_id, limit } = opts;

    try {
      const orders = await orderAPI.getList({ customer_id, limit });
      commit('setOrders', { customerId: customer_id, data: orders });
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setOrders: (state, { customerId, data }) => {
    state.orders = { ...state.orders, [customerId]: data };
  },

  setLoading: (state, value) => {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
