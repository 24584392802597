import { bus, api } from 'shared/core';

class PushEvent {
  init() {
    bus.on('pushInit', this.onPushInit);
  }

  onPushInit = ({ userId, isSubscribed }) => {
    const url = `/dashboard/profile/push/${isSubscribed ? 'subscribe/' : 'unsubscribe/'}`;
    api.endpoint
      .post(url, { user_id: userId })
      .then(r => console.log(r))
      .catch(e => console.error(e));
  };
}

export default new PushEvent();
