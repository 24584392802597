const fixDateForAllBrowsers = dateString => typeof dateString === 'string'
  ? dateString.replace(/-/g, '/') 
  : dateString;

window.Date = class extends Date{
  constructor(...options) {
    if (typeof options[0] === 'string') {
      options[0] = fixDateForAllBrowsers(options[0])
    }
    super(...options);
  }
};