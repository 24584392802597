import { api } from 'shared/core';

class OrderAPI {
  getList = (opts = {}) => {
    const { customer_id, page = 1, limit = 20 } = opts;
    return api.endpoint.get('dashboard/order/get-list', { customer_id, page, limit });
  };
}

export const orderAPI = new OrderAPI();
