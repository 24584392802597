export default [
  {
    path: '/support/customer',
    component: () => import('@/apps/support/views/Customer'),
    children: [
      {
        path: '',
        name: 'support-customer',
        component: () => import('@/apps/support/views/Support/Support'),
        children: [
          {
            path: 'chat/:chat',
            name: 'customer-chat',
            component: () => import('@/apps/support/views/Support/Chat/Chat'),
          },
        ],
      },
    ],
  },

  {
    path: '/support/company',
    component: () => import('@/apps/support/views/Company'),
    children: [
      {
        path: '',
        name: 'support-company',
        component: () => import('@/apps/support/views/Support/Support'),
        children: [
          {
            path: 'chat/:chat',
            name: 'company-chat',
            component: () => import('@/apps/support/views/Support/Chat/Chat'),
          },
        ],
      },
    ],
  },

  {
    path: '/support/employee',
    component: () => import('@/apps/support/views/Employee'),
    children: [
      {
        path: '',
        name: 'support-employee',
        component: () => import('@/apps/support/views/Support/Support'),
        children: [
          {
            path: 'chat/:chat',
            name: 'employee-chat',
            component: () => import('@/apps/support/views/Support/Chat/Chat'),
          },
        ],
      },
    ],
  },
];
