import { api } from 'shared/core';

class SupportAPI {
  getChatList = (opts = {}) => {
    const { page = 1, limit = 20 } = opts;
    let { type } = opts;

    if (type === 'b2c') type = 'customer';
    else if (type === 'b2b') type = 'company';

    const payload = { page, limit };

    if (type) payload.type = type;

    return api.endpoint.get('dashboard/chat/get-list', payload);
  };

  getChatById = (opts = {}) => {
    const { id } = opts;
    return api.endpoint.get('dashboard/chat/get-by-id', { id });
  };

  getChatMessages = (opts = {}) => {
    const { chat_id, page = 1, limit = 20 } = opts;
    return api.endpoint.get('dashboard/chat/message/get-list', { chat_id, page, limit });
  };

  editMessage = (opts = {}) => {
    const { text, chat_id, chat_message_id } = opts;
    return api.endpoint.post('dashboard/chat/message/update', { chat_id, chat_message_id, text });
  };

  sendMessage = (opts = {}) => {
    const { chat_id, text } = opts;
    return api.endpoint.post('dashboard/chat/message/send', { chat_id, text });
  };

  markMessageAsRead = (opts = {}) => {
    const { chat_id, id } = opts;
    return api.endpoint.post('dashboard/chat/message/set-as-read', { chat_id, id });
  };

  getCompanyByEmployee = (opts = {}) => {
    const { id } = opts;
    return api.endpoint.get('dashboard/company-employee/get-by-id', { id });
  };

  downloadFile = (opts = {}) => {
    const { id } = opts;

    const { access_token } = api.endpoint.getTokens();

    return api.endpoint.get(
      'dashboard/chat/message/file/download',
      { id, token: access_token },
      { linkOnly: true },
    );
  };

  uploadFile = (opts = {}) => {
    const { chat_id, file, text = '' } = opts;

    return api.endpoint.post('dashboard/chat/message/file/upload', {
      chat_id,
      file,
      text,
    });
  };
}

export const supportAPI = new SupportAPI();
