import 'animate.css';
import 'normalize.css';
import 'swiper/swiper-bundle.css';
import '@/core/styles.scss';
import 'regenerator-runtime';
import '@/core/scripts/override';

import Vue from 'vue';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { api, bus, Channel, utils } from 'shared/core';
import { auth, personal, profile } from 'shared/modules';
import App from '@/core/App';
import router from '@/core/router';
import store from '@/core/store';

// TODO: move to appropriate place
localStorage.setItem('TLocale', 'ru');

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$bus = bus;
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueAwesomeSwiper);

api.setEndpoint('backOffice/administrator');

utils.getEvents().forEach(({ value }) => value.init(store));
utils.getChannels().forEach(({ value }) => new Channel(value));

const countries = [{ name: 'ru', code: 7, mask: '+7 (###) ###-##-##' }];

personal.connect();
auth.connect({ store, router, opts: { withPassword: true, countries, selectedCountry: 'ru' } });
profile.connect({ store, router });

new Vue({
  router,
  store,
  i18n: new VueI18n({
    locale: 'ru',
    messages: {},
  }),

  render: (h) => h(App),
}).$mount('#app');
